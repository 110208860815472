import PropTypes from 'prop-types'
import React from 'react'

import * as Common from '@rushplay/common'

const currentStepStyle = {
  borderRadius: '50%',
  border: '1px solid #5718DC',
  width: '14px',
  height: '14px',
  fontSize: '10px',
  color: 'white',
}
const previousStepStyle = {
  width: '8px',
  height: '8px',
  lineHeight: '8px',
  borderRadius: '50%',
  background: 'linear-gradient(94deg, #5718DC 0.32%, #1849DC 100%)',
  border: '1px solid #0C132B',
  boxSizing: 'content-box',
}

export function ProgressBar(props) {
  return (
    <Common.Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      position="absolute"
      bottom="-8px"
      left="0px"
      right="0px"
      width="85%"
      margin="auto"
      fontWeight="900"
      fontFamily="Rubik"
      backgroundColor="#0C132B"
      color="#B2C2EE"
      fontSize="6px"
    >
      <Common.Box
        style={props.step === 0 ? currentStepStyle : previousStepStyle}
      >
        1
      </Common.Box>
      <Common.Box
        flexGrow="1"
        height="2px"
        background={
          props.step <= 0
            ? 'none'
            : 'linear-gradient(94deg, #5718DC 0.32%, #1849DC 100%)'
        }
      />
      <Common.Box
        style={
          props.step === 0
            ? {}
            : props.step === 1
            ? currentStepStyle
            : previousStepStyle
        }
      >
        2
      </Common.Box>
      <Common.Box
        flexGrow="1"
        height="2px"
        background={
          props.step === 2
            ? 'linear-gradient(94deg, #5718DC 0.32%, #1849DC 100%)'
            : 'none'
        }
      />
      <Common.Box style={props.step === 2 ? currentStepStyle : {}}>
        3
      </Common.Box>
    </Common.Box>
  )
}

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired,
}

// for @loadable/components
export default ProgressBar

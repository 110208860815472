import css from '@styled-system/css'
import styled from '@emotion/styled'

export const GradientText = styled.h3`
  text-align: center;
  background: linear-gradient(180deg, #fff 0%, #9090ff 100%);
  background-clip: text;
  line-height: 1;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${props =>
    css({
      fontSize: props.fontSize || 3,
      fontFamily: props.fontFamily || 'Rubik',
      fontWeight: props.fontWeight || 800,
      paddingTop: props.pt || 0,
      maxWidth: props.maxWidth || '100%',
    })}
`
